import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  methods: {
    ...mapActions('Creative', [
      'ActionSetCreativeAll',
      'ActionSetCreative',
      'ActionSetCreativeSelect',
      'ActionSetFilterCreative',
      'ActionSetDialogCreative',
      'ActionSetChannelId',
      'ActionSetFormCreativeData',
      'ActionSetCreativeSelections',
      'ActionPushNewImageToCreativeImages',
      'ActionSetFormCreativeImages'
    ]),

    async setChannelId(channelId) {
      this.ActionSetChannelId(channelId);
    },

    async getCreative(page, perPage) {
      let query;
      if (!this.filterCreative) query = '%26status=1';
      else query = `${this.filterCreative}`;
      query += '%26orderBy=id|desc%26';
      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26perPage=12';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=CREATIVE&path=/api/get/creative/search?' + query);
        if (status === 200) {
          this.ActionSetCreativeAll(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCreativeSearch(page, perPage) {
      let query = this.defineCreativeSearchQuery(page, perPage);
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=CREATIVE&path=/api/get/creative/affiliate/search?' + query
        );

        if (status === 200) {
          this.ActionSetCreativeAll(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    defineCreativeSearchQuery(page, perPage) {
      let query;
      if (!this.filterCreative) query = '%26status=1';
      else query = `${this.filterCreative}`;
      query += '%26orderBy=id|desc%26';
      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26perPage=12';
      else query += perPage;

      return query;
    }
  },
  computed: {
    ...mapGetters('Creative', [
      'creativeAll',
      'creative',
      'creativeSelect',
      'filterCreative',
      'dialogCreative',
      'channelId'
    ]),

    ...mapState('Creative', ['formCreativeData', 'creativeSelections', 'formCreativeImages'])
  }
};
